import React, { useState } from 'react';
import '../styles/CustomDropdown.css';
import { selectGender, setGender } from '../state/slices';
import { useSelector, useDispatch } from 'react-redux';

const CustomDropdown = ({ options, onChange }) => {
  const gender = useSelector(selectGender);
  const [selectedValue, setSelectedValue] = useState(gender);

  const handleChange = (event) => {
    const newValue = event.target.value;
    setSelectedValue(newValue);
    onChange(newValue);
  };

  return (
    <div>
      <select className="custom-dropdown" value={selectedValue} onChange={handleChange}>
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default CustomDropdown;
