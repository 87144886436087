import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosRequest from '../utils/api'
export const countSlice = createSlice({
  name: 'count',
  initialState: { value: 0 },
  reducers: {
    setCount: (state, action) => {
      state.value = action.payload
    },
  },
})

export const selectCount = (state) => state.count.value
export const { setCount } = countSlice.actions
export const countReducer = countSlice.reducer

// ................................................................................

export const nameSlice = createSlice({
  name: 'name',
  initialState: { value: 'Tom' },
  reducers: {
    setName: (state, action) => {
      state.value = action.payload
    },
  },
})

export const selectName = (state) => state.name.value
export const { setName } = nameSlice.actions
export const nameReducer = nameSlice.reducer

// ................................................................................

export const genderSlice = createSlice({
  name: 'gender',
  initialState: { value: '--select--' },
  reducers: {
    setGender: (state, action) => {
      state.value = action.payload
    },
  },
})

export const selectGender = (state) => state.gender.value
export const { setGender } = genderSlice.actions
export const genderReducer = genderSlice.reducer

// ................................................................................

export const serviceSlice = createSlice({
  name: 'service',
  initialState: { value: 'SMP' },
  reducers: {
    setService: (state, action) => {
      state.value = action.payload
    },
  },
})

export const selectService = (state) => state.service.value
export const { setService } = serviceSlice.actions
export const serviceReducer = serviceSlice.reducer

// ................................................................................

export const showDialogSlice = createSlice({
  name: 'showDialog',
  initialState: { value: false },
  reducers: {
    setShowDialog: (state, action) => {
      state.value = action.payload
    },
  },
})

export const selectShowDialog = (state) => state.showDialog.value
export const { setShowDialog } = showDialogSlice.actions
export const showDialogReducer = showDialogSlice.reducer

// ................................................................................

export const showLoadingSlice = createSlice({
  name: 'showLoading',
  initialState: { value: false },
  reducers: {
    setShowLoading: (state, action) => {
      state.value = action.payload
    },
  },
})

export const selectShowLoading = (state) => state.showLoading.value
export const { setShowLoading } = showLoadingSlice.actions
export const showLoadingReducer = showLoadingSlice.reducer

// ................................................................................

export const firstNameSlice = createSlice({
  name: 'firstName',
  initialState: { value: '' },
  reducers: {
    setFirstName: (state, action) => {
      state.value = action.payload
    },
  },
})

export const selectFirstName = (state) => state.firstName.value
export const { setFirstName } = firstNameSlice.actions
export const firstNameReducer = firstNameSlice.reducer

// ................................................................................

export const lastNameSlice = createSlice({
  name: 'lastName',
  initialState: { value: '' },
  reducers: {
    setLastName: (state, action) => {
      state.value = action.payload
    },
  },
})

export const selectLastName = (state) => state.lastName.value
export const { setLastName } = lastNameSlice.actions
export const lastNameReducer = lastNameSlice.reducer

// ................................................................................

export const phoneSlice = createSlice({
  name: 'phone',
  initialState: { value: '' },
  reducers: {
    setPhone: (state, action) => {
      state.value = action.payload
    },
  },
})

export const selectPhone = (state) => state.phone.value
export const { setPhone } = phoneSlice.actions
export const phoneReducer = phoneSlice.reducer

// ................................................................................

export const emailSlice = createSlice({
  name: 'email',
  initialState: { value: '' },
  reducers: {
    setEmail: (state, action) => {
      state.value = action.payload
    },
  },
})

export const selectEmail = (state) => state.email.value
export const { setEmail } = emailSlice.actions
export const emailReducer = emailSlice.reducer

// ................................................................................

export const messageSlice = createSlice({
  name: 'message',
  initialState: { value: '' },
  reducers: {
    setMessage: (state, action) => {
      state.value = action.payload
    },
  },
})

export const selectMessage = (state) => state.message.value
export const { setMessage } = messageSlice.actions
export const messageReducer = messageSlice.reducer

// ................................................................................

export const citySlice = createSlice({
  name: 'city',
  initialState: { value: '' },
  reducers: {
    setCity: (state, action) => {
      state.value = action.payload
    },
  },
})

export const selectCity = (state) => state.city.value
export const { setCity } = citySlice.actions
export const cityReducer = citySlice.reducer

// ................................................................................

export const dialogTitleSlice = createSlice({
  name: 'dialogTitle',
  initialState: { value: '' },
  reducers: {
    setDialogTitle: (state, action) => {
      state.value = action.payload
    },
  },
})

export const selectDialogTitle = (state) => state.dialogTitle.value
export const { setDialogTitle } = dialogTitleSlice.actions
export const dialogTitleReducer = dialogTitleSlice.reducer

// ................................................................................

export const dialogDescSlice = createSlice({
  name: 'dialogDesc',
  initialState: { value: '' },
  reducers: {
    setDialogDesc: (state, action) => {
      state.value = action.payload
    },
  },
})

export const selectDialogDesc = (state) => state.dialogDesc.value
export const { setDialogDesc } = dialogDescSlice.actions
export const dialogDescReducer = dialogDescSlice.reducer

// ................................................................................
export const getBlogs = createAsyncThunk('getBlogs', async () => {
  try {
    const response = await axiosRequest.get('/api/getAllBlogs', {
      headers: {
        Authorization:
          'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2Njc2ZGQzNzJhYzhkYTk3YWIwYTA4YjMiLCJpYXQiOjE3MTk4NjYxOTYsImV4cCI6MTcxOTg4NDE5Nn0.6jP0kZj98qsnJVc-MP7bBhpXu2pfNAMyIPi72lYKEnE',
      },
    });
    return response.data;
  } catch (error) {
    // console.error(error);
  }
});
export const blogsSlice = createSlice({
  name: 'blogs',
  initialState: {
    blogs: {
      message: 'Blogs fetched successfully',
      status: true,
      data: [
        {
          photo: {
            public_id: 'blogs/dunmd8kxmuj9earaqfy8',
            url: 'https://www.particleformen.com/wp-content/uploads/2022/02/healthy-scalp.jpg'
          },
          _id: '6676819d115fdaa55fad3884',
          title: 'Effective Treatments for Hair Loss',
          category: 'Chemical Treatments2',
          description: 'Explore various treatments for hair loss, including medications, natural remedies, and lifestyle changes.',
          isDeleted: false,
          isActive: true,
          createdAt: '2024-06-22T07:37:05.872Z',
          updatedAt: '2024-06-22T13:01:00.613Z'
        },
        {
          photo: {
            public_id: 'blogs/dunmd8kxmuj9earaqfy8',
            url: 'https://surethik.com/cdn/shop/articles/how-to-hide-your-scalp-if-you-have-thinning-hair-US.png?v=1639377548'
          },
          _id: '6676819d115fdaa55fad3884',
          title: 'Effective Treatments for Hair Loss',
          category: 'Chemical Treatments2',
          description: 'Explore various treatments for hair loss, including medications, natural remedies, and lifestyle changes.',
          isDeleted: false,
          isActive: true,
          createdAt: '2024-06-22T07:37:05.872Z',
          updatedAt: '2024-06-22T13:01:00.613Z'
        }
      ]
    }, loading: false, error: null, selectedBlog: {}
  },
  reducers: {
    setSelctedBlog: (state, action) => {
      state.selectedBlog = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBlogs.pending, (state, action) => {
        state.loading = true;
        state.blogs = []
      })
      .addCase(getBlogs.fulfilled, (state, action) => {
        state.loading = true;
        state.blogs = action.payload;
      })
      .addCase(getBlogs.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
})

export const { setSelctedBlog } = blogsSlice.actions
export const blogsReducer = blogsSlice.reducer

// ................................................................................