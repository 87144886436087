import React, { useState, useEffect } from 'react';
import '../../styles/HomePage/Navbar.css';
import { useNavigate, useLocation } from "react-router-dom";
import useWindowDimensions from '../../hooks/useWindowDimensions';

function Navbar() {
    const location = useLocation();

    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [submenuOpen, setSubmenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
        setSubmenuOpen(false)
    };

    const toggleSubmenu = () => {
        setSubmenuOpen(!submenuOpen);
    };

    const { width } = useWindowDimensions();
    const isDesktop = width >= 1025;
    const isTablet = width >= 768 && width <= 1024;
    const isMobile = width <= 767;

    return (
        <header className='navbar-container'>
            <div className='navbar-container-in'>
                <div className='navbar-left-side' onClick={() => {
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth'
                    });
                    return;
                }}>
                    {/* <div className="image-container">
                        <img src="/logo.png" alt="Your Image" className="circular-image" />
                    </div> */}
                    <div className='micro-inc-text'>
                        {/* <strong>Get Fit with Gaurav</strong> */}
                        Get Fit with Gaurav
                    </div>
                </div>
                <div className='navbar-right-side'>
                    {/* <div className='nav-option'>
                        Services
                    </div>
                    <div className='nav-option'>
                        Trainings
                    </div> */}
                    {/* <button className="transparent-button2" onClick={() => {
                        navigate("/test/");
                    }}>
                        Book a free consultation
                    </button> */}
                    <button className="hamburger-menu" onClick={toggleMenu}>
                        <div className={`line ${isOpen ? 'open' : ''}`}></div>
                        <div className={`line ${isOpen ? 'open' : ''}`}></div>
                        <div className={`line ${isOpen ? 'open' : ''}`}></div>
                    </button>
                    <ul className={isOpen ? 'nav-menu active' : 'nav-menu'}>
                        <SpaceBetween></SpaceBetween>
                        <li><div className={'nav-menu-title'} onClick={() => {
                            setIsOpen(false);
                            window.scrollTo({
                                top: 0,
                                behavior: 'smooth'
                            });
                            return;
                        }}>H O M E</div></li>
                        <SpaceBetween></SpaceBetween>
                        <li className='options-menu-container'>
                            <div className={'nav-menu-title'} onClick={() => {
                                if (isTablet) {
                                    setIsOpen(false);
                                    window.scrollTo({
                                        top: 1640,
                                        behavior: 'smooth'
                                    });
                                    return;
                                }
                                if (isMobile) {
                                    setIsOpen(false);
                                    window.scrollTo({
                                        top: 1900,
                                        behavior: 'smooth'
                                    });
                                    return;
                                }
                            }}>S E R V I C E S</div>
                        </li>
                        <SpaceBetween></SpaceBetween>
                        <li><div className={'nav-menu-title'} onClick={() => {
                            console.log(`document.documentElement.scrollHeight = ${document.documentElement.scrollHeight}`)
                            setIsOpen(false);
                            if (isTablet) {
                                window.scrollTo({
                                    top: document.documentElement.scrollHeight - 3400,
                                    behavior: 'smooth'
                                });
                                return;
                            }
                            window.scrollTo({
                                top: document.documentElement.scrollHeight - 5250,
                                behavior: 'smooth'
                            });
                            return;
                        }}>F A Q</div></li>
                        <SpaceBetween></SpaceBetween>
                        <li><div className='nav-menu-title' onClick={() => {
                            setIsOpen(false);
                            window.scrollTo({
                                top: document.documentElement.scrollHeight,
                                behavior: 'smooth'
                            });
                            return;
                        }}>C O N T A C T</div></li>
                        <SpaceBetween></SpaceBetween>
                    </ul>
                    <ul className='nav-menu2'>
                        <li><div className={'nav-menu-title'} onClick={() => {
                            setIsOpen(false);
                            window.scrollTo({
                                top: 0,
                                behavior: 'smooth'
                            });
                            return;
                        }}>Home</div></li>
                        <li className="options-menu-container">
                            <div className={'nav-menu-title'} onClick={() => {
                                if (isDesktop) {
                                    window.scrollTo({
                                        top: 1350,
                                        behavior: 'smooth'
                                    });
                                    return;
                                }
                                if (isTablet) {
                                    window.scrollTo({
                                        top: 3200,
                                        behavior: 'smooth'
                                    });
                                    return;
                                }
                                if (isMobile) {
                                    window.scrollTo({
                                        top: 3200,
                                        behavior: 'smooth'
                                    });
                                    return;
                                }
                            }}>Services</div>
                        </li>
                        <li><div className={'nav-menu-title'} onClick={() => {
                            console.log(`document.documentElement.scrollHeight = ${document.documentElement.scrollHeight}`)
                            window.scrollTo({
                                top: document.documentElement.scrollHeight - 2265,
                                behavior: 'smooth'
                            });
                            return;
                        }}>FAQ</div></li>
                        <li><div className='nav-menu-title' onClick={() => {
                            window.scrollTo({
                                top: document.documentElement.scrollHeight,
                                behavior: 'smooth'
                            });
                            return;
                        }}>Contact</div></li>
                    </ul>
                </div>
            </div>
        </header>
    );
}

function SpaceBetween() {
    return (
        <div style={{ height: "50px" }}>
        </div>
    );
}

function SpaceBetween2() {
    return (
        <div style={{ height: "30px" }}>
        </div>
    );
}

function SpaceBetween3() {
    return (
        <div style={{ height: "25px" }}>
        </div>
    );
}

export default Navbar;
