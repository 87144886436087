import ValidationCheck from './ValidationCheck'
import { isValidString, validEmail, validPhoneNumber } from '../utils/Utilities'

class FormDetails {
    constructor({ firstName, lastName, email, phone, gender, city, message } = {}) {
        this._firstName = firstName;
        this._lastName = lastName;
        this._email = email;
        this._phone = phone;
        this._gender = gender;
        this._city = city;
        this._message = message;
    }

    // Getter and setter for firstName
    get firstName() {
        return this._firstName;
    }

    set firstName(value) {
        this._firstName = value;
    }

    // Getter and setter for lastName
    get lastName() {
        return this._lastName;
    }

    set lastName(value) {
        this._lastName = value;
    }

    // Getter and setter for email
    get email() {
        return this._email;
    }

    set email(value) {
        this._email = value;
    }

    // Getter and setter for phone
    get phone() {
        return this._phone;
    }

    set phone(value) {
        this._phone = value;
    }

    // Getter and setter for gender
    get gender() {
        return this._gender;
    }

    set gender(value) {
        this._gender = value;
    }

    // Getter and setter for city
    get city() {
        return this._city;
    }

    set city(value) {
        this._city = value;
    }

    // Getter and setter for message
    get message() {
        return this._message;
    }

    set message(value) {
        this._message = value;
    }

    // Method to serialize the instance to a JSON string
    toJSON() {
        return JSON.stringify({
            firstName: this._firstName,
            lastName: this._lastName,
            email: this._email,
            phone: this._phone,
            gender: this._gender,
            city: this._city,
            message: this._message
        });
    }

    // Static method to deserialize a JSON string to an instance of FormDetails
    static fromJSON(jsonString) {
        const data = JSON.parse(jsonString);
        return new FormDetails({
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            phone: data.phone,
            gender: data.gender,
            city: data.city,
            message: data.message
        });
    }


    // Validation method
    allFieldsAreValid() {
        // if (!this._firstName || !this._lastName || !this._email || !this._phone || !this._gender || !this._city || !this._message) {
        //     return new ValidationCheck({ isValid: false, error: "All mandatory fields are not set" });
        // }
        if (!isValidString(this._firstName)) {
            return new ValidationCheck({ isValid: false, error: "First name is missing" });
        }
        if (!isValidString(this._lastName)) {
            return new ValidationCheck({ isValid: false, error: "Last name is missing" });
        }
        if (!isValidString(this._email)) {

            return new ValidationCheck({ isValid: false, error: "Email is missing" });
        }
        if (!validEmail(this._email)) {
            return new ValidationCheck({ isValid: false, error: "Improper email address" });
        }
        if (!isValidString(this._phone)) {
            return new ValidationCheck({ isValid: false, error: "Phone number is missing" });
        }
        if (!validPhoneNumber(this._phone)) {
            return new ValidationCheck({ isValid: false, error: "Improper phone number" });
        }
        if (!isValidString(this._gender) || (isValidString(this._gender) && this._gender==='--select--')) {
            return new ValidationCheck({ isValid: false, error: "Gender is missing" });
        }
        // if (!isValidString(this._city)) {
        //     return new ValidationCheck({ isValid: false, error: "City is missing" });
        // }
        // if (!isValidString(this._message)) {
        //     return new ValidationCheck({ isValid: false, error: "Message is missing" });
        // }
        return new ValidationCheck({ isValid: true });
    }


}

export default FormDetails;