import React, { useRef } from 'react';
import '../../styles/HomePage/Testimonials.css';
import { LuChevronLeftCircle, LuChevronRightCircle } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import { SMP_FOR_MEN_URL_1, SMP_FOR_MEN_URL_2, SMP_FOR_WOMEN_URL_1, SMP_FOR_WOMEN_URL_2, SMP_FOR_ALOPECIA_URL_1, SMP_FOR_ALOPECIA_URL_2, SMP_FOR_SCARS_URL_1, SMP_FOR_SCARS_URL_2, TREATMENTS_HEADING_1, TREATMENTS_HEADING_2, SMP_FOR_MEN_CARD_TITLE, SMP_FOR_MEN_CARD_DESCRIPTION, SMP_FOR_MEN_CARD_FOOTER, SMP_FOR_WOMEN_CARD_TITLE, SMP_FOR_WOMEN_CARD_DESCRIPTION, SMP_FOR_WOMEN_CARD_FOOTER, SMP_FOR_ALOPECIA_CARD_TITLE, SMP_FOR_ALOPECIA_CARD_DESCRIPTION, SMP_FOR_ALOPECIA_CARD_FOOTER, SMP_FOR_SCARS_CARD_TITLE, SMP_FOR_SCARS_CARD_DESCRIPTION, SMP_FOR_SCARS_CARD_FOOTER } from '../../constants';

function Testimonials() {
    return (
        <div>
            <TestimonialsMainDesktop></TestimonialsMainDesktop>
            <TestimonialsMainTablet></TestimonialsMainTablet>
            <TestimonialsMainMobile></TestimonialsMainMobile>
        </div>
    );
};

function TestimonialsMainDesktop() {
    const navigate = useNavigate();
    const styles = {
        buttonContainer: {
            // backgroundColor: 'red',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItms: 'center',
            fontSize: '30px',
            margin: '0px 5px 0px 0px'
        },
        icon: {
            cursor: 'pointer',
            color: 'grey'
        },
    };

    const scrollRef = useRef(null);

    const scrollLeft = () => {
        scrollRef.current.scrollBy({ left: -200, behavior: 'smooth' });
    };

    const scrollRight = () => {
        scrollRef.current.scrollBy({ left: 200, behavior: 'smooth' });
    };
    return (
        <div className='testimonials-main-desktop'>
            <div className='testimonials-head'>
                <div className='testimonials-head-column'>
                    <div className='testimonials-text'>
                        TESTIMONIALS
                    </div>
                    <div className='testimonials-text2'>
                        What Clients Say
                    </div>
                </div>
                <div style={styles.buttonContainer}>
                    <LuChevronLeftCircle onClick={scrollLeft} style={styles.icon} />
                    <div style={{ width: '15px' }}></div>
                    <LuChevronRightCircle onClick={scrollRight} style={styles.icon} />
                </div>
            </div>
            <div ref={scrollRef} className='testimonial-cards'>
                <TestimonialCard
                    text1="Nitesh Mishra"
                    text2="I began my training journey at 92kg on October 7, 2023, and I'm now down to a fit and healthy 71kg. This transformation wouldn't have been possible without Coach Gayrav's guidance and unwavering support. His expertise and encouragement have been instrumental in my progress. I'm excited to keep pushing my limits and breaking barriers with his help!"
                    text3={SMP_FOR_MEN_CARD_FOOTER}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpformen/");
                    }}
                    image1={"/client-1.JPG"}
                ></TestimonialCard>
                <div className='tc-space'></div>
                <TestimonialCard
                    text1="Yash Karkera"
                    text2="I'm thrilled to share my fitness journey and thank Coach Gaurav for his incredible support. When I started training with him in 2021 at 64 kg, his expert guidance and encouragement helped me achieve a strong 78 kg. My progress highlights the impact of dedication and the right coach. We've tackled challenges and celebrated milestones together, and Coach Gaurav's support has been key to my success."
                    image1={"/client-2.jpeg"}
                    text3={SMP_FOR_WOMEN_CARD_FOOTER}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpforwomen/");
                    }}
                ></TestimonialCard>
                <div className='tc-space'></div>
                <TestimonialCard
                    text1={"Sunandan Sridharan"}
                    text2="I've been training with Coach Gaurav online for nearly two years, participating in 4 to 5 sessions each week that combine strength, cardio, and boxing. His dedication and punctuality are remarkable, and his ability to push me to my limits through virtual sessions has been transformative. Coach Gaurav's commitment to my progress shines through in every workout, making this online training experience exceptionally valuable."
                    text3={SMP_FOR_ALOPECIA_CARD_FOOTER}
                    image1={"client-3.JPG"}
                    image2={SMP_FOR_ALOPECIA_URL_2}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpforalopecia/");
                    }}
                ></TestimonialCard>
                <div className='tc-space'></div>
                <TestimonialCard
                    text1={"Rohan yadav"}
                    text2="Coach Gaurav has truly transformed my fitness journey over the past three years. His tailored training and expert guidance have helped me build muscle and reach goals I never thought possible. His support and motivation have been invaluable. I highly recommend him to anyone committed to making a real change."
                    text3={SMP_FOR_SCARS_CARD_FOOTER}
                    image1={"/client-4.jpeg"}
                    image2={SMP_FOR_SCARS_URL_2}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpforscars/");
                    }}
                ></TestimonialCard>
            </div>
        </div>
    );
}

function TestimonialsMainTablet() {
    const navigate = useNavigate();
    return (
        <div className='testimonials-main-tablet'>
            <div className='testimonials-head'>
                <div className='testimonials-head-column'>
                    <div className='testimonials-text'>
                        TESTIMONIALS
                    </div>
                    <div className='testimonials-text2'>
                        What Clients Say
                    </div>
                </div>
            </div>
            <div className='testimonial-cards1'>
                <TestimonialCard
                    text1="Nitesh Mishra"
                    text2="I began my training journey at 92kg on October 7, 2023, and I'm now down to a fit and healthy 71kg. This transformation wouldn't have been possible without Coach Gayrav's guidance and unwavering support. His expertise and encouragement have been instrumental in my progress. I'm excited to keep pushing my limits and breaking barriers with his help!"
                    text3={SMP_FOR_MEN_CARD_FOOTER}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpformen/");
                    }}
                    image1={"/client-1.JPG"}
                ></TestimonialCard>
                <TestimonialCard
                    text1="Yash Karkera"
                    text2="I'm thrilled to share my fitness journey and thank Coach Gaurav for his incredible support. When I started training with him in 2021 at 64 kg, his expert guidance and encouragement helped me achieve a strong 78 kg. My progress highlights the impact of dedication and the right coach. We've tackled challenges and celebrated milestones together, and Coach Gaurav's support has been key to my success."
                    image1={"/client-2.jpeg"}
                    text3={SMP_FOR_WOMEN_CARD_FOOTER}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpforwomen/");
                    }}
                ></TestimonialCard>
            </div>
            <div className='testimonial-cards2'>
                <TestimonialCard
                    text1={"Sunandan Sridharan"}
                    text2="I've been training with Coach Gaurav online for nearly two years, participating in 4 to 5 sessions each week that combine strength, cardio, and boxing. His dedication and punctuality are remarkable, and his ability to push me to my limits through virtual sessions has been transformative. Coach Gaurav's commitment to my progress shines through in every workout, making this online training experience exceptionally valuable."
                    text3={SMP_FOR_ALOPECIA_CARD_FOOTER}
                    image1={"client-3.JPG"}
                    image2={SMP_FOR_ALOPECIA_URL_2}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpforalopecia/");
                    }}
                ></TestimonialCard>
                <TestimonialCard
                    text1={"Rohan yadav"}
                    text2="Coach Gaurav has truly transformed my fitness journey over the past three years. His tailored training and expert guidance have helped me build muscle and reach goals I never thought possible. His support and motivation have been invaluable. I highly recommend him to anyone committed to making a real change."
                    text3={SMP_FOR_SCARS_CARD_FOOTER}
                    image1={"/client-4.jpeg"}
                    image2={SMP_FOR_SCARS_URL_2}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpforscars/");
                    }}
                ></TestimonialCard>
            </div>
        </div>
    );
}

function TestimonialsMainMobile() {
    const navigate = useNavigate();
    return (
        <div className='testimonials-main-mobile'>
            <div className='testimonials-head'>
                <div className='testimonials-head-column'>
                    <div className='testimonials-text'>
                        TESTIMONIALS
                    </div>
                    <div className='testimonials-text2'>
                        What Clients Say
                    </div>
                </div>
            </div>
            <div className='testimonial-cards'>
                <TestimonialCard
                    text1="Nitesh Mishra"
                    text2="I began my training journey at 92kg on October 7, 2023, and I'm now down to a fit and healthy 71kg. This transformation wouldn't have been possible without Coach Gayrav's guidance and unwavering support. His expertise and encouragement have been instrumental in my progress. I'm excited to keep pushing my limits and breaking barriers with his help!"

                    text3={SMP_FOR_MEN_CARD_FOOTER}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpformen/");
                    }}
                    image1={"/client-1.JPG"}
                ></TestimonialCard>
                <TestimonialCard
                    text1="Yash Karkera"
                    text2="I'm thrilled to share my fitness journey and thank Coach Gaurav for his incredible support. When I started training with him in 2021 at 64 kg, his expert guidance and encouragement helped me achieve a strong 78 kg. My progress highlights the impact of dedication and the right coach. We've tackled challenges and celebrated milestones together, and Coach Gaurav's support has been key to my success."
                    image1={"/client-2.jpeg"}
                    text3={SMP_FOR_WOMEN_CARD_FOOTER}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpforwomen/");
                    }}
                ></TestimonialCard>
                <TestimonialCard
                    text1={"Sunandan Sridharan"}
                    text2="I've been training with Coach Gaurav online for nearly two years, participating in 4 to 5 sessions each week that combine strength, cardio, and boxing. His dedication and punctuality are remarkable, and his ability to push me to my limits through virtual sessions has been transformative. Coach Gaurav's commitment to my progress shines through in every workout, making this online training experience exceptionally valuable."
                    text3={SMP_FOR_ALOPECIA_CARD_FOOTER}
                    image1={"client-3.JPG"}
                    image2={SMP_FOR_ALOPECIA_URL_2}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpforalopecia/");
                    }}
                ></TestimonialCard>
                <TestimonialCard
                    text1={"Rohan yadav"}
                    text2="Coach Gaurav has truly transformed my fitness journey over the past three years. His tailored training and expert guidance have helped me build muscle and reach goals I never thought possible. His support and motivation have been invaluable. I highly recommend him to anyone committed to making a real change."
                    text3={SMP_FOR_SCARS_CARD_FOOTER}
                    image1={"/client-4.jpeg"}
                    image2={SMP_FOR_SCARS_URL_2}
                    onClickAction={() => {
                        window.scrollTo(0, 0);
                        navigate("/smpforscars/");
                    }}
                ></TestimonialCard>
            </div>
        </div>
    );
}

function TestimonialCard({ text1, text2, text3, image1, image2, onClickAction }) {
    return (
        <div className='testimonial-card-component-container' >
            <div className='testimonial-card-component-in'>
                <div className='testimonial-card-component-images'>
                    <div className='testimonial-card-component-image'>
                        <img src={image1} height={"100%"} style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}></img>
                    </div>
                    {/* <div className='testimonial-card-component-image'>
                        <img src={image2} height={"100%"} style={{ borderTopRightRadius: "10px" }}></img>
                    </div> */}
                </div>
                <div className='testimonial-card-component-content-area'>
                    <div className='testimonial-card-component-text0'>
                        {/* SMP for men */}
                        {text1}
                    </div>
                    <div className='testimonial-card-component-text1'>
                        {/* "I don't think about my hair anymore. I get dressed, get out of bed, and go to work, that's it. It looks so natural and so real! I just wish I had gotten it done sooner." */}
                        "{text2}"
                    </div>
                    {/* <div className='testimonial-card-component-text2'>
                     
                        {text3}
                    </div> */}
                </div>
            </div>
        </div>
    );
}

export default Testimonials;
