export function validEmail(email) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
}

export function validPhoneNumber(phoneNumber) {
    const regex = /^\d{10}$/;
    return regex.test(phoneNumber);
}

export function isValidString(str) {
    return str !== undefined && str !== null && str !== 'undefined' && str !== 'null' && str.trim() !== '';
}

export const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString('en-US', options);
    const day = date.getDate();
    const daySuffix = (day) => {
        if (day > 3 && day < 21) return 'TH'; // exception for 11th to 20th
        switch (day % 10) {
            case 1: return 'ST';
            case 2: return 'ND';
            case 3: return 'RD';
            default: return 'TH';
        }
    };
    return formattedDate.replace(/\d{1,2}/, day + daySuffix(day)).toUpperCase();
};