export const BRAND_LOGO = '/logo.png';

// Home.js
export const HOME_VIDEO = 'https://firebasestorage.googleapis.com/v0/b/pro22-82a40.appspot.com/o/fit-gg-rt%2Fimg11.jpg?alt=media&token=c46d45f6-7df0-4730-ac91-af787547b01d';
export const HOME_VIDEO2 = "https://firebasestorage.googleapis.com/v0/b/pro22-82a40.appspot.com/o/fit-gg-rt%2Fimg2.jpeg?alt=media&token=5107c1f6-24e0-4404-a09d-95e487a4a2a0";
export const HOME_VIDEO_HEADER_1 = 'WE DON\'T JUST';
export const HOME_VIDEO_HEADER_12 = 'TRANSFORM YOUR BODY,';
export const HOME_VIDEO_HEADER_2 = 'BUT YOUR LIFESTYLE';
export const HOME_VIDEO_HEADER_3 = 'Personal training | Online training | Animal flow | Functional training | Boxing | Nutrition plans | Trx suspension training';
export const HOME_VIDEO_BUTTON_TEXT = 'GET STARTED';
// ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

// About.js
// export const FOUNDER_IMAGE = 'https://firebasestorage.googleapis.com/v0/b/pro22-82a40.appspot.com/o/fit-gg-rt%2Fgg-profile.png?alt=media&token=a69b423f-64f9-4709-8e11-fe02a09e17f6';
export const FOUNDER_IMAGE = 'gg.jpeg';
export const ABOUT_TITLE = 'About the coach';
// export const ABOUT_TEXT = "I'm Gaurav Gulati, a Mumbai-based fitness expert with 7 years of experience. Specializing in fat loss and lean mass gain, I offer personalized training programs for all ages, both online and offline. Certified in personal training, sports nutrition, and various fitness disciplines, I focus on improving strength, endurance, and flexibility. Join me to transform your lifestyle and achieve your fitness goals today!";
export const ABOUT_TEXT = "I'm Gaurav Gulati, a Mumbai-based fitness expert with 7 years of experience. Specializing in fat loss and lean mass gain, I offer personalized training programs for all ages, both online and offline. Certified in personal training, sports nutrition, and various fitness disciplines, I focus on improving strength, endurance, and flexibility. My approach combines cutting-edge fitness techniques with tried-and-true methods, ensuring safe and effective results. Whether you're a beginner looking to start your fitness journey or an athlete aiming to enhance performance, I tailor each program to meet your unique needs and goals. I provide continuous support and motivation, helping you stay on track and overcome any challenges. Join me to transform your lifestyle and achieve your fitness goals today! Together, we can build a healthier, stronger, and more confident you. Reach out now to start your personalized fitness journey and take the first step towards a better you.";
export const ABOUT_BUTTON_TEXT = 'Get in Touch';
// ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

// Treatments.js
export const TREATMENTS_HEADING_1 = 'SERVICES';
export const TREATMENTS_HEADING_2 = 'Getting in shape is finally easy!';
//................................................................
export const SMP_FOR_MEN_URL_1 = '/personal-training.jpg';
export const SMP_FOR_MEN_URL_2 = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fsmp-for-men%2Fsmp-for-men2.PNG?alt=media&token=54f6b8a6-e381-47ed-b2f4-ee980c9c8c67';
export const SMP_FOR_MEN_CARD_TITLE = 'Personal Training';
export const SMP_FOR_MEN_CARD_DESCRIPTION = "Customized workout plans for your fitness goals, designed to suit your individual needs and preferences. Each plan is tailored based on your fitness level, lifestyle, and specific objectives, ensuring optimal results.";
export const SMP_FOR_MEN_CARD_FOOTER = 'Explore SMP for Men';
//................................................................
export const SMP_FOR_WOMEN_URL_1 = '/online-training.jpg';
export const SMP_FOR_WOMEN_URL_2 = '/online-training.jpg';
export const SMP_FOR_WOMEN_CARD_TITLE = 'Online Training';
export const SMP_FOR_WOMEN_CARD_DESCRIPTION = 'Virtual training sessions for remote clients, offering personalized workout plans and real-time feedback. Enjoy the convenience and flexibility to work out from anywhere with expert support.';
export const SMP_FOR_WOMEN_CARD_FOOTER = 'Discover SMP for Women';
// export const SMP_FOR_WOMEN_URL_1 = 'https://img.freepik.com/free-photo/front-view-people-training-together-gym_23-2150289964.jpg?t=st=1720894036~exp=1720897636~hmac=46765f4c6e0de69ab9d3c82e99ea4568fc26d958134900e11384f7106749e8e2&w=2000';
// export const SMP_FOR_WOMEN_URL_2 = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fsmp-for-women%2FScreenshot%202024-06-02%20at%209.41.07%E2%80%AFPM.png?alt=media&token=e574cec6-23c6-409a-8e1c-6aec6d8dc51e';
// export const SMP_FOR_WOMEN_CARD_TITLE = 'Group Classes';
// export const SMP_FOR_WOMEN_CARD_DESCRIPTION = 'Fun and challenging workouts in a group setting that foster a sense of community and camaraderie. Our group training sessions are designed to keep you motivated and engaged that cater to all fitness levels.';
// export const SMP_FOR_WOMEN_CARD_FOOTER = 'Discover SMP for Women';
//................................................................
export const SMP_FOR_ALOPECIA_URL_1 = '/animal-flow.jpg';
export const SMP_FOR_ALOPECIA_URL_2 = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fsmp-for-alopecia%2FSMP%20for%20Alopecia.PNG?alt=media&token=1e44dfab-2c88-4789-9c85-1f5f4bea0a49';
export const SMP_FOR_ALOPECIA_CARD_TITLE = 'Animal Flow';
export const SMP_FOR_ALOPECIA_CARD_DESCRIPTION = "Animal Flow is a fitness program that improves strength, mobility, and coordination through ground-based, animal-inspired movements like crawling, jumping, and rolling. It enhances overall body control.";
export const SMP_FOR_ALOPECIA_CARD_FOOTER = 'Learn about SMP for Alopecia';
// export const SMP_FOR_ALOPECIA_URL_1 = 'https://img.freepik.com/free-photo/healthy-menu-recipe-food-diet_53876-122837.jpg?t=st=1720894122~exp=1720897722~hmac=5c945d67b94fe1a24c4ac14e723cc16503b86eabe8dd7c7a1c532420216179f2&w=2000';
// export const SMP_FOR_ALOPECIA_URL_2 = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fsmp-for-alopecia%2FSMP%20for%20Alopecia.PNG?alt=media&token=1e44dfab-2c88-4789-9c85-1f5f4bea0a49';
// export const SMP_FOR_ALOPECIA_CARD_TITLE = 'Nutritional Guidance';
// export const SMP_FOR_ALOPECIA_CARD_DESCRIPTION = "Expert advice on healthy eating habits tailored to your unique needs and goals. Our nutritional guidance provides you with personalized meal plans, practical tips, and strategies to help you make informed food choices.";
// export const SMP_FOR_ALOPECIA_CARD_FOOTER = 'Learn about SMP for Alopecia';
//................................................................
export const SMP_FOR_SCARS_URL_1 = '/functional-training.webp';
export const SMP_FOR_SCARS_URL_2 = '';
export const SMP_FOR_SCARS_CARD_TITLE = 'Functional Training';
export const SMP_FOR_SCARS_CARD_DESCRIPTION = 'Functional training focuses on exercises that mimic everyday movements to enhance strength and stability. By engaging multiple muscle groups and joints, it improves overall body function.';
export const SMP_FOR_SCARS_CARD_FOOTER = 'Explore SMP for Scars';
// export const SMP_FOR_SCARS_URL_1 = 'https://img.freepik.com/free-photo/full-shot-man-sitting-yoga-mat_23-2149249473.jpg?t=st=1720894198~exp=1720897798~hmac=d63aaefff896dfbbb648862b3f3758ef45bf12462f2b2614ed4abe3d79e8499a&w=2000';
// export const SMP_FOR_SCARS_URL_2 = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fsmp-for-scars%2FSMP%20for%20scars.PNG?alt=media&token=45eac690-ef78-4e1b-8662-7f64d70e4364';
// export const SMP_FOR_SCARS_CARD_TITLE = 'Online Coaching';
// export const SMP_FOR_SCARS_CARD_DESCRIPTION = 'Virtual training sessions for remote clients, offering personalized workout plans and real-time feedback. Enjoy the convenience and flexibility to work out from anywhere with expert support.';
// export const SMP_FOR_SCARS_CARD_FOOTER = 'Explore SMP for Scars';
// ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

// WhyNumber1.js
export const WCU_TITLE = 'Why Choose Us';
export const BULLET_NUMBER_1 = '01';
export const BULLET_NUMBER_1_TEXT = 'I create customized workout and nutrition plans tailored to your individual goals, fitness level, and lifestyle, ensuring you achieve optimal results.';
export const BULLET_NUMBER_2 = '02';
export const BULLET_NUMBER_2_TEXT = 'With 7 years of experience and certifications in personal training, sports nutrition, and various fitness disciplines, I provide knowledgeable and effective guidance.';
export const BULLET_NUMBER_3 = '03';
export const BULLET_NUMBER_3_TEXT = 'I offer ongoing support, motivation, and real-time feedback, both online and offline, helping you stay on track and overcome any challenges on your fitness journey.';
export const WCU_BUTTON_TEXT = 'GET A FREE QUOTE';
// ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

// CompareChart.js
export const CC_HEADING_1 = 'COMPARE';
export const CC_HEADING_2 = 'MICRO INC. SMP vs other hair loss options';
//................................................................
export const UPPER_HEADING_1_IMAGE = '/bald-man.png';
export const UPPER_HEADING_1 = 'SMP';
//................................................................
export const UPPER_HEADING_2_IMAGE = '/hair-transplant.png';
export const UPPER_HEADING_2 = 'Hair Transplant';
//................................................................
export const UPPER_HEADING_3_IMAGE = '/hair-system.png';
export const UPPER_HEADING_3 = 'Hair System';
//................................................................
export const UPPER_HEADING_4_IMAGE = '/rogaine.png';
export const UPPER_HEADING_4 = 'Rogaine/Propecia';
//................................................................
export const SIDE_HEADING_1 = 'Non surgical';
export const SIDE_HEADING_2 = 'Immediate Results';
export const SIDE_HEADING_3 = 'Low maintenance';
export const SIDE_HEADING_4 = 'Affordable';
export const SIDE_HEADING_5 = 'Looks realistic';
// ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

// ConsultationForm.js
export const CF_HEADING_1 = 'FREE CONSULTATION';
export const CF_HEADING_2 = 'Get your FREE Scalp Micropigmentation consultation';
export const CF_HEADING_3 = 'Speak with a scalp micropigmentation expert today!';
//................................................................
export const CF_RIGHT_TITLE = 'Types of Consultations';
//................................................................
export const CF_RIGHT_BULLET_NUMBER_1 = '1';
export const CF_RIGHT_BULLET_NUMBER_1_TEXT = 'In Person – get all your questions answered face to face by the trainer';
//................................................................
export const CF_RIGHT_BULLET_NUMBER_2 = '2';
export const CF_RIGHT_BULLET_NUMBER_2_TEXT = 'Phone – speak with trainer over the phone';
//................................................................
export const CF_RIGHT_BULLET_NUMBER_3 = '3';
export const CF_RIGHT_BULLET_NUMBER_3_TEXT = 'Facetime – Learn more with a Facetime chat';
//................................................................
export const CF_RIGHT_BULLET_NUMBER_4 = '4';
export const CF_RIGHT_BULLET_NUMBER_4_TEXT = 'Receive a quote and treatment plan based on your specific hair loss goals';
// ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

// Faq.js
export const FAQ_HEADING_1 = 'FAQ';
export const FAQ_HEADING_2 = 'Questions about Personalized Fitness Training';
export const FAQ_DESCRIPTION = "These answers will help you understand the basics of my fitness training approach. For more details, feel free to reach out with your inquiries or schedule a complimentary consultation. My personalized fitness programs are designed to deliver effective and sustainable results, tailored to your specific goals and fitness level. With continuous support and guidance, I'll help you achieve your fitness aspirations, whether you're aiming to lose weight, build muscle, or enhance overall health and well-being. Let's work together to transform your lifestyle and achieve long-lasting fitness success.";
//................................................................
export const FAQ_TILE_1_HEADING = 'What services do you offer?';
export const FAQ_TILE_1_DESCRIPTION = 'We offer a variety of personal training services including one-on-one sessions, group classes, online training, nutritional guidance, and customized fitness plans. Our goal is to provide comprehensive support to help you achieve your fitness goals.';
//................................................................
export const FAQ_TILE_2_HEADING = 'How do I get started with a personal trainer?';
export const FAQ_TILE_2_DESCRIPTION = 'To get started, you can book a free consultation with one of our certified personal trainers. During this session, we\'ll discuss your fitness goals, current fitness level, and any medical conditions or limitations you may have. Based on this information, we\'ll recommend a personalized training plan for you.';
//................................................................
export const FAQ_TILE_3_HEADING = 'Can I train with a friend or family member?';
export const FAQ_TILE_3_DESCRIPTION = 'Yes, we offer semi-private training sessions for small groups of 2-4 people. Training with a friend or family member can be a great way to stay motivated and make your workouts more enjoyable. Please contact us for pricing and availability of semi-private sessions.';
//................................................................
export const FAQ_TILE_4_HEADING = 'Do you offer online training sessions?';
export const FAQ_TILE_4_DESCRIPTION = 'Yes, we offer online training sessions for those who prefer to work out from home or have a busy schedule. Can provide virtual one-on-one sessions, workout plans, and regular check-ins to keep you motivated and on track with your fitness goals.';
//................................................................
export const FAQ_TILE_5_HEADING = 'What if I have a medical condition or injury?';
export const FAQ_TILE_5_DESCRIPTION = 'We are experienced in working with clients who have various medical conditions or injuries. We will tailor your workout plan to accommodate your specific needs and ensure that all exercises are safe and effective. Please provide any relevant medical information during your initial consultation.';
//................................................................
//................................................................
export const FAQ_TILE_6_HEADING = 'How long do SMP results last?';
export const FAQ_TILE_6_DESCRIPTION = 'The longevity of SMP results can vary depending on factors such as skin type, lifestyle, and exposure to the sun. On average, SMP results can last between 3 to 5 years before requiring touch-up sessions to maintain the desired appearance.';
//................................................................
export const FAQ_TILE_7_HEADING = 'Are there any side effects of SMP?';
export const FAQ_TILE_7_DESCRIPTION = 'While SMP is considered safe, some potential side effects may include temporary redness, swelling, or irritation in the treated area. These side effects are typically mild and subside within a few days following the procedure.';
//................................................................
export const FAQ_TILE_8_HEADING = 'Can SMP be reversed or removed?';
export const FAQ_TILE_8_DESCRIPTION = 'SMP is considered a permanent cosmetic solution, but it can be adjusted or corrected if needed. Laser treatments or specialized removal techniques may be used to modify or remove SMP pigment if desired.';
//................................................................
export const FAQ_TILE_9_HEADING = 'How do I choose a qualified SMP practitioner?';
export const FAQ_TILE_9_DESCRIPTION = 'When considering SMP treatment, it\'s essential to research and select a reputable and experienced practitioner who specializes in scalp micropigmentation. Look for certifications, reviews, and before-and-after photos to ensure you\'re in capable hands.';
//................................................................
export const FAQ_TILE_10_HEADING = 'Is SMP covered by insurance?';
export const FAQ_TILE_10_DESCRIPTION = 'SMP is typically considered a cosmetic procedure and is not covered by insurance. However, some clinics may offer financing options or payment plans to help make SMP more affordable for patients.';
//................................................................
export const FAQ_BUTTON_TEXT = 'View All FAQ';
// ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

// Footer.js
export const FOOTER_NEWSLETTER_HEADING = 'NEWSLETTER';
export const FOOTER_EMAIL_FIELD_HEADING = 'Enter email to get in touch';
export const FOOTER_BUTTON_TEXT = 'Get a free consultation';
export const FOOTER_HASHTAG = '#GETFIT';
//................................................................
export const FOOTER_TEXT_1 = 'ALL RIGTHS RESERVED © GAURAV GULATI 2024';
export const FOOTER_TEXT_2 = 'PRIVACY POLICY';
export const FOOTER_TEXT_3 = 'TERMS & CONDITIONS';
//................................................................
export const CONTACT_EMAIL = 'gulati12326@gmail.com';
export const CONTACT_PHONE = '+91 916 710 7906';
//................................................................
export const CONTACT_LINK_FACEBOOK = 'https://www.facebook.com/Gulatifitness?mibextid=LQQJ4d';
export const CONTACT_LINK_INSTAGRAM = 'https://www.instagram.com/getfitwith__gaurav?igsh=ajE5eTQ2cjd0bXBs&utm_source=qr';
export const CONTACT_LINK_WHATSAPP = 'https://wa.me/+919167107906';
export const CONTACT_LINK_YOUTUBE = '';
export const CONTACT_LINK_TIKTOK = '';
//................................................................
export const FOOTER_TREATMENTS_HEADING = 'SERVICES';
export const FOOTER_TREATMENTS_OPTION_1 = 'Personal Training';
export const FOOTER_TREATMENTS_OPTION_2 = 'Online Training';
export const FOOTER_TREATMENTS_OPTION_3 = 'Animal Flow';
export const FOOTER_TREATMENTS_OPTION_4 = 'Functional Training';
export const FOOTER_TREATMENTS_OPTION_5 = 'Boxing';
export const FOOTER_TREATMENTS_OPTION_6 = 'Nutrition Plans';
export const FOOTER_TREATMENTS_OPTION_7 = 'Trx Suspension Training';
//................................................................
export const FOOTER_LEARN_HEADING = 'LEARN';
export const FOOTER_LEARN_OPTION_1 = 'FAQ';
export const FOOTER_LEARN_OPTION_2 = 'Blog';
export const FOOTER_LEARN_OPTION_3 = 'FAQ';
export const FOOTER_LEARN_OPTION_4 = 'Blog';
export const FOOTER_LEARN_OPTION_5 = 'Press';
export const FOOTER_LEARN_OPTION_6 = 'Contact';
//................................................................
export const FOOTER_LOCATIONS_HEADING = 'LOCATIONS';
export const FOOTER_LOCATIONS_OPTION_1 = 'New York';
export const FOOTER_LOCATIONS_OPTION_2 = 'Los Angeles';
export const FOOTER_LOCATIONS_OPTION_3 = 'Scar camouflage';
export const FOOTER_LOCATIONS_OPTION_4 = 'Houston';
export const FOOTER_LOCATIONS_OPTION_5 = 'Chicago';
export const FOOTER_LOCATIONS_OPTION_6 = 'Reviews';
export const FOOTER_LOCATIONS_OPTION_7 = 'South Florida';
// ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

// SmpForMenPage.js
export const SMP_FOR_MEN_BANNER_IMAGE = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fplaceholder-image.png?alt=media&token=43e501c7-907a-46b1-b9f0-06384816d3e2';
//................................................................
export const SMP_FOR_MEN_CARD1_IMAGE_1 = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fsmp-for-men%2Fcrew-cut%2FCrewcut%20before.PNG?alt=media&token=45cbfef5-8a85-48a6-a67a-b737540d1fa7';
export const SMP_FOR_MEN_CARD1_IMAGE_2 = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fsmp-for-men%2Fcrew-cut%2FCrewcut%20after.PNG?alt=media&token=a6bb800e-fc7b-4644-a52f-b54f8d31d090';
//................................................................
export const SMP_FOR_MEN_CARD2_IMAGE_1 = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fsmp-for-men%2FScreenshot%202024-06-02%20at%209.21.48%E2%80%AFPM.png?alt=media&token=c0b946c6-dc93-4286-84cc-ab3b1dfce48a';
export const SMP_FOR_MEN_CARD2_IMAGE_2 = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fsmp-for-men%2Fsmp-for-men2.PNG?alt=media&token=54f6b8a6-e381-47ed-b2f4-ee980c9c8c67';
//................................................................
export const SMP_FOR_MEN_CARD3_IMAGE_1 = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fsmp-for-men%2Fcombine-hair-transplant%2FIMG_7789.PNG?alt=media&token=5f2aa061-e384-42e0-b79d-ad6840f69335';
export const SMP_FOR_MEN_CARD3_IMAGE_2 = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fsmp-for-men%2Fcombine-hair-transplant%2FCombine%20Hair%20Transplant%20with%20Scalp%20Micropigmentation%20.PNG?alt=media&token=8fe14033-5cab-4b31-aa6b-f217e4a857ec';
// ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

// SmpForWomen.js
export const SMP_FOR_WOMEN_BANNER_IMAGE = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fplaceholder-image.png?alt=media&token=43e501c7-907a-46b1-b9f0-06384816d3e2';
//................................................................
export const SMP_FOR_WOMEN_CARD1_IMAGE_1 = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fsmp-for-men%2Fcrew-cut%2FCrewcut%20before.PNG?alt=media&token=45cbfef5-8a85-48a6-a67a-b737540d1fa7';
export const SMP_FOR_WOMEN_CARD1_IMAGE_2 = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fsmp-for-men%2Fcrew-cut%2FCrewcut%20after.PNG?alt=media&token=a6bb800e-fc7b-4644-a52f-b54f8d31d090';
//................................................................
export const SMP_FOR_WOMEN_CARD2_IMAGE_1 = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fsmp-for-women%2FScreenshot%202024-06-02%20at%209.40.56%E2%80%AFPM.png?alt=media&token=97760e83-b6e7-4934-bd2a-4a49b59ad5ec';
export const SMP_FOR_WOMEN_CARD2_IMAGE_2 = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fsmp-for-women%2FScreenshot%202024-06-02%20at%209.41.07%E2%80%AFPM.png?alt=media&token=e574cec6-23c6-409a-8e1c-6aec6d8dc51e';
//................................................................
export const SMP_FOR_WOMEN_CARD3_IMAGE_1 = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fsmp-for-men%2Fcombine-hair-transplant%2FIMG_7789.PNG?alt=media&token=5f2aa061-e384-42e0-b79d-ad6840f69335';
export const SMP_FOR_WOMEN_CARD3_IMAGE_2 = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fsmp-for-men%2Fcombine-hair-transplant%2FCombine%20Hair%20Transplant%20with%20Scalp%20Micropigmentation%20.PNG?alt=media&token=8fe14033-5cab-4b31-aa6b-f217e4a857ec';
// ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

// SmpForAlopecia.js
export const SMP_FOR_ALOPECIA_BANNER_IMAGE = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fplaceholder-image.png?alt=media&token=43e501c7-907a-46b1-b9f0-06384816d3e2';
//................................................................
export const SMP_FOR_ALOPECIA_CARD1_IMAGE_1 = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fplaceholder-image.png?alt=media&token=43e501c7-907a-46b1-b9f0-06384816d3e2';
export const SMP_FOR_ALOPECIA_CARD1_IMAGE_2 = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fplaceholder-image.png?alt=media&token=43e501c7-907a-46b1-b9f0-06384816d3e2';
//................................................................
export const SMP_FOR_ALOPECIA_CARD2_IMAGE_1 = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fplaceholder-image.png?alt=media&token=43e501c7-907a-46b1-b9f0-06384816d3e2';
export const SMP_FOR_ALOPECIA_CARD2_IMAGE_2 = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fplaceholder-image.png?alt=media&token=43e501c7-907a-46b1-b9f0-06384816d3e2';
//................................................................
export const SMP_FOR_ALOPECIA_CARD3_IMAGE_1 = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fplaceholder-image.png?alt=media&token=43e501c7-907a-46b1-b9f0-06384816d3e2';
export const SMP_FOR_ALOPECIA_CARD3_IMAGE_2 = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fplaceholder-image.png?alt=media&token=43e501c7-907a-46b1-b9f0-06384816d3e2';
// ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

// SmpForScars.js
export const SMP_FOR_SCARS_BANNER_IMAGE = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fplaceholder-image.png?alt=media&token=43e501c7-907a-46b1-b9f0-06384816d3e2';
//................................................................
export const SMP_FOR_SCARS_CARD1_IMAGE_1 = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fplaceholder-image.png?alt=media&token=43e501c7-907a-46b1-b9f0-06384816d3e2';
export const SMP_FOR_SCARS_CARD1_IMAGE_2 = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fplaceholder-image.png?alt=media&token=43e501c7-907a-46b1-b9f0-06384816d3e2';
//................................................................
export const SMP_FOR_SCARS_CARD2_IMAGE_1 = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fplaceholder-image.png?alt=media&token=43e501c7-907a-46b1-b9f0-06384816d3e2';
export const SMP_FOR_SCARS_CARD2_IMAGE_2 = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fplaceholder-image.png?alt=media&token=43e501c7-907a-46b1-b9f0-06384816d3e2';
//................................................................
export const SMP_FOR_SCARS_CARD3_IMAGE_1 = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fplaceholder-image.png?alt=media&token=43e501c7-907a-46b1-b9f0-06384816d3e2';
export const SMP_FOR_SCARS_CARD3_IMAGE_2 = 'https://firebasestorage.googleapis.com/v0/b/micro-inc-smp.appspot.com/o/micro-inc-smp%2Fplaceholder-image.png?alt=media&token=43e501c7-907a-46b1-b9f0-06384816d3e2';
// ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
