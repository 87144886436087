import React from 'react';
import '../../styles/HomePage/Home.css';
import Navbar from './Navbar';
import { HOME_VIDEO, HOME_VIDEO_HEADER_1, HOME_VIDEO_HEADER_12, HOME_VIDEO_HEADER_2, HOME_VIDEO_HEADER_3, HOME_VIDEO_BUTTON_TEXT } from '../../constants';
import useWindowDimensions from '../../hooks/useWindowDimensions';

function Home() {
    return (
        <div>
            <HomeDesktop></HomeDesktop>
            <HomeMobile></HomeMobile>
        </div>
    );
}

function HomeDesktop() {
    const { width } = useWindowDimensions();
    const isDesktop = width >= 1025;
    const isTablet = width >= 768 && width <= 1024;
    const isMobile = width <= 767;

    function reValue({ desktop, tablet, mobile }) {
        if (isDesktop) {
            return desktop;
        }
        if (isTablet) {
            return tablet;
        }
        if (isMobile) {
            return mobile;
        }
    }
    return (
        <div className='home-video-container'>
            {/* <video className='home-video-component' autoPlay muted loop>
                <source src={HOME_VIDEO} type="video/mp4" />
                Your browser does not support the video tag.
            </video> */}
            <img className='home-video-component' src={HOME_VIDEO}>

            </img>
            <div className='home-video-overlay'>
                <div className='home-video-overlay-column'>
                    <span className='home-video-overlay-column-text1'>
                        {HOME_VIDEO_HEADER_1}
                    </span>
                    <span className='home-video-overlay-column-text1'>
                        {HOME_VIDEO_HEADER_12}
                    </span>
                    <span className='home-video-overlay-column-text2'>
                        {HOME_VIDEO_HEADER_2}
                    </span>
                    <div className='home-video-overlay-column-text3'>
                        {HOME_VIDEO_HEADER_3}
                    </div>
                    <button className="quoteButtonHome" onClick={reValue({
                        desktop: () => {
                            // console.log(`the width bro : ${width}`);
                            // window.scrollTo(0, 3200);
                            window.scrollTo({
                                top: document.documentElement.scrollHeight - 3120,
                                behavior: 'smooth'
                            });
                        },
                        tablet: () => {
                            // console.log(`the width bro : ${width}`);
                            window.scrollTo({
                                top: document.documentElement.scrollHeight - 4500,
                                behavior: 'smooth'
                            });
                        },
                        mobile: () => {
                            // console.log(`the width bro : ${width}`);
                            window.scrollTo({
                                top: document.documentElement.scrollHeight - 4400,
                                behavior: 'smooth'
                            });
                        },
                    })}>
                        {HOME_VIDEO_BUTTON_TEXT}
                    </button>
                </div>
            </div>
            {/* <Navbar></Navbar> */}
        </div>
    );
}

function HomeMobile() {
    const { width } = useWindowDimensions();
    const isDesktop = width >= 1025;
    const isTablet = width >= 768 && width <= 1024;
    const isMobile = width <= 767;

    function reValue({ desktop, tablet, mobile }) {
        if (isDesktop) {
            return desktop;
        }
        if (isTablet) {
            return tablet;
        }
        if (isMobile) {
            return mobile;
        }
    }
    return (
        <div className='home-video-container-mobile'>
            {/* <video className='home-video-component' autoPlay muted loop>
                <source src={HOME_VIDEO} type="video/mp4" />
                Your browser does not support the video tag.
            </video> */}
            <img className='home-video-component' src={HOME_VIDEO}></img>
            <div className='home-video-overlay'>
                <div className='home-video-overlay-column'>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        justifyContent: 'center',
                    }}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            // backgroundColor: 'green',
                            width: '91%',
                        }}>
                            <span className='home-video-overlay-column-text1'>
                                {HOME_VIDEO_HEADER_1}
                            </span>
                            <span className='home-video-overlay-column-text1'>
                                {HOME_VIDEO_HEADER_12}
                            </span>
                            <span className='home-video-overlay-column-text2'>
                                {HOME_VIDEO_HEADER_2}
                            </span>
                            <div className='home-video-overlay-column-text3'>
                                {HOME_VIDEO_HEADER_3}
                            </div>
                        </div>
                    </div>
                    <div style={{
                        // backgroundColor: 'red',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                    }}>
                        <button className="quoteButtonHome" onClick={reValue({
                            desktop: () => {
                                // console.log(`the width bro : ${width}`);
                                window.scrollTo(0, 3200);
                            },
                            tablet: () => {
                                // console.log(`the width bro : ${width}`);
                                window.scrollTo(0, 5500);
                            },
                            mobile: () => {
                                // console.log(`the width bro : ${width}`);
                                window.scrollTo({
                                    top: document.documentElement.scrollHeight - 6350,
                                    behavior: 'smooth'
                                });
                            },
                        })}>
                            {HOME_VIDEO_BUTTON_TEXT}
                        </button>
                    </div>
                </div>
            </div>
            {/* <Navbar></Navbar> */}
        </div>
    );
}


// return (
//     <div className='home-video-container'>
//         <video className='home-video-component' autoPlay muted loop>
//             <source src={HOME_VIDEO} type="video/mp4" />
//             Your browser does not support the video tag.
//         </video>
//         <div className='home-video-overlay'>
//             <div>
//                 <div className='home-video-overlay-column'>
//                     <div className='home-video-overlay-column-text1'>
//                         {HOME_VIDEO_HEADER_1}
//                     </div>
//                     <div className='home-video-overlay-column-text2'>
//                         Scalp Micropigmentation
//                     </div>
//                     <div className='home-video-overlay-column-text3'>
//                         Scalp Micropigmentation is a highly effective, non-invasive, low maintenance solution to hair loss that delivers immediate, long term results.
//                     </div>
//                     <button className="quoteButtonHome">
//                         {HOME_VIDEO_BUTTON_TEXT}
//                     </button>
//                 </div>
//             </div>
//         </div>
//         <Navbar></Navbar>
//     </div>
// ); 

{/* <div className='space-between-navbar'></div> */ }

export default Home;