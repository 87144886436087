import React from 'react';
import useWindowDimensions from '../../hooks/useWindowDimensions';

function Banner() {
    const { width } = useWindowDimensions();
    const isDesktop = width >= 1025;
    const isTablet = width >= 768 && width <= 1024;
    const isMobile = width <= 767;

    function reValue({ desktop, tablet, mobile }) {
        if (isDesktop) {
            return desktop;
        }
        if (isTablet) {
            return tablet;
        }
        if (isMobile) {
            return mobile;
        }
    }
    return (
    <a href="https://ampasnutrition.com/" target="_blank" rel="" style={{ textDecoration: 'none' }}>
        <div style={reValue({
            desktop: { display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', margin: '100px 0' },
            tablet: { display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', margin: '100px 0' },
            mobile: { display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', margin: '50px 0' },
        })}>
            <div style={reValue({
                desktop: { backgroundColor: 'black', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '265px', width: '89vw' },
                tablet: { backgroundColor: 'black', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '240px', width: '80vw' },
                mobile: { backgroundColor: 'black', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '180px', width: '90vw' },
            })}>
                <img 
                    src="/ampas.jpeg" 
                    alt="Banner" 
                    style={{ 
                        maxWidth: '100%', 
                        maxHeight: '100%', 
                        objectFit: 'cover' 
                    }} 
                />
            </div>
        </div>
    </a>
    );
}

export default Banner;